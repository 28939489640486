const BASE_API_URL_LOCAL = "http://localhost:3001/app/v1";
const BASE_API_URL_STAGING = "https://staging.vimcal.com/app/v1";
const BASE_API_URL_DOGFOOD = "https://dogfood.vimcal.com/app/v1";
const BASE_API_URL_DEVELOPMENT = "https://dev.vimcal.com/app/v1";
const BASE_API_URL_PRODUCTION = "https://prod.vimcal.com/app/v1";
const BASE_API_URL_TESTING = "https://testing.vimcal.com/app/v1";

const BASE_API_URL = getBaseURL();
export function constructRequestURL(path, v2 = false) {
  const version = v2 ? "v2" : "v1";
  // const versionURL = BASE_API_URL[version]
  const updatedBaseAPI = BASE_API_URL.includes("v1")
    ? BASE_API_URL.replace("v1", "")
    : BASE_API_URL; // need to do this for backward compatibility on heroku

  const versionURL = updatedBaseAPI + version ?? "v1";

  return `${versionURL}/${path}`;
}

function getBaseURL() {
  if (!!process.env.REACT_APP_SERVER_BASE_URL) {
    return process.env.REACT_APP_SERVER_BASE_URL;
  }

  return BASE_API_URL_PRODUCTION;
  // return BASE_API_URL_LOCAL;
  // return BASE_API_URL_STAGING;
  // return BASE_API_URL_DOGFOOD;
  // return BASE_API_URL_DEVELOPMENT;
  // return BASE_API_URL_TESTING;
}
