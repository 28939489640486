import React, { Component } from "react";
import { Calendar, Clock, Globe, MapPin, Video } from "react-feather";
import {
  BACKEND_HANGOUT,
  BACKEND_NO_CONFERENCING,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_ZOOM,
  HANGOUT_LOGO_URL,
  PHONE_MEETING_ICON,
  ZOOM_LOGO_URL,
  WHATS_APP_ICON,
  BACKEND_CUSTOM_CONFERENCING,
} from "../services/globalVariables";
import {
  AddAbbrevationToTimeZone,
  convertMinutesIntoDayHoursAndMinutes,
  convertOutlookConferencingToHumanReadable,
  getOutlookConferencingIconURL,
  guessTimeZone,
  isOutlookConferencingOption,
} from "../services/commonUsefulFunctions";
import classNames from "classnames";
import moment from "moment";
import TimeZoneSelect from "./timeZoneSelect";

const DEFAULT_FONT_SIZE = "font-size-12";
const ICON_SIZE = 16;

class EventInfoWithIcons extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      additionalClassName,
      containerClassName,
      shouldShowTimeZoneDropdown,
    } = this.props;

    const defaultMarginTop = "mt-1.5";
    const MARGIN_RIGHT = this.props.addMarginRightToEachSection ? "mr-2" : "";

    return (
      <div
        className={classNames("width-100-percent", containerClassName ?? "")}
      >
        {this.props.info.onClickedConfirmTime &&
        this.props.info.selectedTime ? (
          <div
            className={classNames(
              "display-flex-flex-direction-row align-items-center",
              MARGIN_RIGHT,
              defaultMarginTop,
              DEFAULT_FONT_SIZE,
              additionalClassName ?? ""
            )}
          >
            <Calendar size={ICON_SIZE} className="mr-2.5" />

            {moment(this.props.info.selectedTime.start).format("dddd ll")}
          </div>
        ) : null}
        {this.renderTimeOrDuration({ defaultMarginTop })}
        {this.props.info.location && (
          <div
            className={classNames(
              "display-flex-flex-direction-row align-items-center",
              MARGIN_RIGHT,
              defaultMarginTop,
              DEFAULT_FONT_SIZE,
              additionalClassName ?? ""
            )}
          >
            <div className="margin-right-10">
              <MapPin size={ICON_SIZE} />
            </div>

            <div className="truncate-text" style={{ maxWidth: "240px" }}>
              {this.props.info.location || "Home"}
            </div>
          </div>
        )}

        {this.props.info.conferencing &&
          this.props.info.conferencing !== BACKEND_NO_CONFERENCING && (
            <div
              className={classNames(
                "display-flex-flex-direction-row align-items-center",
                MARGIN_RIGHT,
                defaultMarginTop,
                DEFAULT_FONT_SIZE,
                additionalClassName ?? ""
              )}
            >
              <div className="margin-right-10 padding-top-3">
                {this.renderConferencingIcon()}
              </div>

              {this.determineConferencingText()}
            </div>
          )}

        <div
          className={classNames(
            "display-flex-flex-direction-row",
            MARGIN_RIGHT,
            defaultMarginTop,
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            shouldShowTimeZoneDropdown ? "items-center" : ""
          )}
        >
          <Globe size={ICON_SIZE} className="mr-2.5 flex" />

          {this.renderTimeZone()}
        </div>
      </div>
    );
  }

  renderTimeZone() {
    const { selectedTimeZone, shouldShowTimeZoneDropdown, onSelectTimeZone } =
      this.props;
    if (!shouldShowTimeZoneDropdown || !onSelectTimeZone) {
      return (
        <div>
          {AddAbbrevationToTimeZone(selectedTimeZone ?? guessTimeZone())}
        </div>
      );
    }
    return (
      <TimeZoneSelect
        selectedTimeZone={selectedTimeZone}
        onSelectTimeZone={onSelectTimeZone}
      />
    );
  }

  renderTimeOrDuration({ defaultMarginTop }) {
    const { additionalClassName } = this.props;
    if (this.props.info.onClickedConfirmTime && this.props.info.selectedTime) {
      return (
        <div
          className={classNames(
            "display-flex-flex-direction-row",
            "align-items-center",
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            defaultMarginTop ?? ""
          )}
        >
          <Clock size={ICON_SIZE} className="mr-2.5" />
          {moment(this.props.info.selectedTime.start).format("h:mm A")}
        </div>
      );
    }

    if (this.props.info.duration) {
      return (
        <div
          className={classNames(
            "display-flex-flex-direction-row",
            "align-items-center",
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            defaultMarginTop
          )}
        >
          <Clock size={ICON_SIZE} className="mr-2.5" />
          {convertMinutesIntoDayHoursAndMinutes(this.props.info.duration)}
        </div>
      );
    }

    return null;
  }

  renderConferencingIcon() {
    if (this.props.info.conferencing === BACKEND_ZOOM) {
      return <img alt="" width="16px" height="16px" src={ZOOM_LOGO_URL} />;
    } else if (this.props.info.conferencing === BACKEND_HANGOUT) {
      return <img alt="" width="16px" height="16px" src={HANGOUT_LOGO_URL} />;
    } else if (this.props.info.conferencing === BACKEND_PHONE) {
      return <img alt="" width="16px" height="16px" src={PHONE_MEETING_ICON} />;
    } else if (this.props.info.conferencing === BACKEND_WHATS_APP) {
      return <img alt="" width="16px" height="16px" src={WHATS_APP_ICON} />;
    } else if (isOutlookConferencingOption(this.props.info.conferencing)) {
      return (
        <img
          alt=""
          width="16px"
          height="16px"
          src={getOutlookConferencingIconURL(this.props.info.conferencing)}
        />
      );
    } else {
      return <Video size={ICON_SIZE} />;
    }
  }

  determineConferencingText() {
    let conferencing;

    if (isOutlookConferencingOption(this.props.info.conferencing)) {
      return convertOutlookConferencingToHumanReadable(
        this.props.info.conferencing
      );
    }

    switch (this.props.info.conferencing) {
      case BACKEND_ZOOM:
        conferencing = "Zoom";
        break;
      case BACKEND_PHONE:
        conferencing = "Phone call";
        break;
      case BACKEND_HANGOUT:
        conferencing = "Google Meet";
        break;
      case BACKEND_WHATS_APP:
        conferencing = "WhatsApp";
        break;
      case BACKEND_CUSTOM_CONFERENCING:
        conferencing = this.props.info.custom_conferencing_name;
        break;
      default:
        break;
    }

    return conferencing;
  }
}

export default EventInfoWithIcons;
